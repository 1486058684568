/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LinkWrapper } from './LinkWrapper';

export type WebsiteRestNewsItem = {
    date?: string;
    labelType?: string;
    linkWrapper?: LinkWrapper;
    title?: string;
    type?: WebsiteRestNewsItem.type;
    metaDescription?: string;
    isLive?: boolean;
    newsTeaserTitle?: string;
    hasHighlightBackgroundGradient?: boolean;
    advertorialLogo?: string;
    gaPromotionName?: string;
    id?: string;
    gaAnalyticsEnabled?: string;
    retinaAdvertorialLogo?: string;
    hasLive?: boolean;
    newsTeaserLabel?: string;
    hasShiftedUpImage?: boolean;
    thumbnail?: string;
    metaTag?: string;
    gaPromotionId?: string;
    advertorialText?: string;
    highlightBackgroundColor?: string;
    label?: string;
    newsTeaserImageUUID?: string;
    gaCreativeName?: string;
    square?: boolean;
    overriddenThumbnail?: string;
    advertorialLogoUUID?: string;
    newsTeaserText?: string;
    imageUUID?: string;
};

export namespace WebsiteRestNewsItem {

    export enum type {
        ARTICLE = 'ARTICLE',
        PRO = 'PRO',
        ADVERTORIAL = 'ADVERTORIAL',
        AUDIO = 'AUDIO',
        VIDEO = 'VIDEO',
        MARKETING = 'MARKETING',
    }


}

