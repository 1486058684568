/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SportDataMatch } from '../models/SportDataMatch';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SportDataV1MatchesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get list of dates, that has any match planned
     * Get list of dates, that has any match planned. List of UNIX timestamps, at the beginning of day, in UTC
     * @returns number OK
     * @throws ApiError
     */
    public getDatesWithMatches({
        month = '2021-03',
    }: {
        /**
         * Month in format yyyy-MM
         */
        month?: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/dates/{month}',
            path: {
                'month': month,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get matches for specified list of id's
     * Get matches for specified list of id's
     * @returns SportDataMatch OK
     * @throws ApiError
     */
    public getMatchesListForMatchIds({
        hash,
        requestBody,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * MD5 hash of match IDs, sorted alphabetically and joined into one string without any delimiter
         */
        hash: string,
        /**
         * Body - list of ids
         */
        requestBody: Array<string>,
        /**
         * Bookmaker id, if neither is provided, VI default settings will be used
         */
        bookmakerId?: number,
        /**
         * Bookmaker id (2), if neither is provided, VI default settings will be used
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<SportDataMatch>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sportdata/v1/matches/ids',
            query: {
                'hash': hash,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
            body: requestBody,
            mediaType: '*/*',
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get matches list for tournament
     * This endpoint works in 2 ways:
     * - request without dates (initial request):
     * return matches for +- 14 days (28 days in total), expect at least 15 matches in past + 15 matches in future, if not found, extend range until found
     * - request with date range (load more clicked - app calculates last shown match, and if user want's to browse to past or future, and sends dates)
     * return matches for range, if not 15 found, extend range
     *
     *
     * @returns SportDataMatch OK
     * @throws ApiError
     */
    public getMatchesListForTournament({
        startDate,
        reverse = false,
        tournamentId = 'bp1sjjiswd4t3nw86vf6yq7hm',
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Start date of matches to be returned. String in format yyyy-MM-dd. It has to be always provided together with endDate. If both not provided, current date will be used to calculate date range. Default date range is 4 weeks (+- 2 weeks)
         */
        startDate?: string,
        /**
         * Should past matches from startDate be returned
         */
        reverse?: boolean,
        /**
         * Tournament that matches should be returned from.
         */
        tournamentId?: string,
        /**
         * Bookmaker id, if neither is provided, VI default settings will be used
         */
        bookmakerId?: number,
        /**
         * Bookmaker id (2), if neither is provided, VI default settings will be used
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<SportDataMatch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/tournament/{tournamentId}',
            path: {
                'tournamentId': tournamentId,
            },
            query: {
                'startDate': startDate,
                'reverse': reverse,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
            },
        });
    }

    /**
     * Get matches for a homepage grid block by uuid
     * Get all matches for a given block uuid
     * @returns SportDataMatch OK
     * @throws ApiError
     */
    public getMatchesListForHomepageBlock({
        uuid,
    }: {
        /**
         * uuid of the given matches block.
         */
        uuid: string,
    }): CancelablePromise<Array<SportDataMatch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/homepage/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get future matches list for team
     * This endpoint return matches for + 60 days, if not found, extend range for another 100 days
     * @returns SportDataMatch OK
     * @throws ApiError
     */
    public getFutureMatchesListForTeam({
        startDate,
        teamId = 'd0zdg647gvgc95xdtk1vpbkys',
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Start date of matches to be returned. String in format yyyy-MM-dd. If not provided, current date will be used to calculate date range. Default date range is 60 days
         */
        startDate?: string,
        /**
         * Team that matches should be returned.
         */
        teamId?: string,
        /**
         * Bookmaker id, if neither is provided, VI default settings will be used
         */
        bookmakerId?: number,
        /**
         * Bookmaker id (2), if neither is provided, VI default settings will be used
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<SportDataMatch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/team/{teamId}/fixtures',
            path: {
                'teamId': teamId,
            },
            query: {
                'startDate': startDate,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
            },
        });
    }

    /**
     * Get matches list for date range
     * Get all matches played in specified dates for competitions, that are configured in Magnolia. Use timespan not longer than 72 hours. Response will be generated and cached for: ttlLong (10 minutes) if request involves current day or ttlVeryLong (100 minutes) if request doesn't involve current day
     * @returns SportDataMatch OK
     * @throws ApiError
     */
    public getMatchesListForSpecifiedDates({
        startDate,
        endDate,
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Start date of matches to be returned. String in format yyyy-MM-dd. If not provided, current date will be used
         */
        startDate?: string,
        /**
         * End date of matches to be returned. String in format yyyy-MM-dd. If not provided, current date will be used
         */
        endDate?: string,
        /**
         * Bookmaker id, if neither is provided, VI default settings will be used
         */
        bookmakerId?: number,
        /**
         * Bookmaker id (2), if neither is provided, VI default settings will be used
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<SportDataMatch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches',
            query: {
                'startDate': startDate,
                'endDate': endDate,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get matches list for team and tournament
     * Get all matches played by team in tournament
     * @returns SportDataMatch OK
     * @throws ApiError
     */
    public getMatchesListForTeamInTournament({
        tournamentId,
        teamId = 'd0zdg647gvgc95xdtk1vpbkys',
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Tournament that matches should be returned from. If not provided, matches from all active tournaments that team participates will be returned.
         */
        tournamentId: string,
        /**
         * Team whose matches should be returned
         */
        teamId?: string,
        /**
         * Bookmaker id, if neither is provided, VI default settings will be used
         */
        bookmakerId?: number,
        /**
         * Bookmaker id (2), if neither is provided, VI default settings will be used
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<SportDataMatch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/team/{teamId}',
            path: {
                'teamId': teamId,
            },
            query: {
                'tournamentId': tournamentId,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get future matches list for tournament
     * This endpoint return matches for + 60 days, if not found, extend range for another 100 days
     * @returns SportDataMatch OK
     * @throws ApiError
     */
    public getFutureMatchesListForTournament({
        startDate,
        tournamentId = 'bp1sjjiswd4t3nw86vf6yq7hm',
        bookmakerId,
        secondBookmakerId,
    }: {
        /**
         * Start date of matches to be returned. String in format yyyy-MM-dd. If not provided, current date will be used to calculate date range.Default date range is 60 days
         */
        startDate?: string,
        /**
         * Tournament that matches should be returned.
         */
        tournamentId?: string,
        /**
         * Bookmaker id, if neither is provided, VI default settings will be used
         */
        bookmakerId?: number,
        /**
         * Bookmaker id (2), if neither is provided, VI default settings will be used
         */
        secondBookmakerId?: number,
    }): CancelablePromise<Array<SportDataMatch>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/matches/tournament/{tournamentId}/fixtures',
            path: {
                'tournamentId': tournamentId,
            },
            query: {
                'startDate': startDate,
                'bookmakerId': bookmakerId,
                'secondBookmakerId': secondBookmakerId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                406: `Not acceptable`,
            },
        });
    }

}
