/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrackResponse } from '../models/TrackResponse';
import type { TracksResponse } from '../models/TracksResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TracksService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * All Tracks
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>seasons</code> <code>stages</code></p>
     *
     * @returns TracksResponse Successful response
     * @throws ApiError
     */
    public getAllTracks({
        include,
    }: {
        include?: Array<'seasons' | 'stages'>,
    }): CancelablePromise<TracksResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/tracks',
            query: {
                'include': include,
            },
        });
    }

    /**
     * Track by ID
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>seasons</code> <code>stages</code></p>
     *
     * @returns TrackResponse Successful response
     * @throws ApiError
     */
    public getTrackById({
        trackId,
        include,
    }: {
        trackId: number,
        include?: Array<'seasons' | 'stages'>,
    }): CancelablePromise<TrackResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/tracks/{trackID}',
            path: {
                'trackID': trackId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Tracks by Season ID
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <h2 id="filter-options"><strong>Filter Options</strong></h2>
     * <p><code>seasons</code> <code>stages</code></p>
     *
     * @returns TracksResponse Successful response
     * @throws ApiError
     */
    public getTracksBySeasonId({
        seasonId,
        include,
        filterStage,
    }: {
        seasonId: number,
        include?: Array<'seasons' | 'stages'>,
        filterStage?: 'race' | 'first practice' | 'second practice' | 'first qualification' | 'second qualification' | 'third qualification',
    }): CancelablePromise<TracksResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/tracks/season/{seasonID}',
            path: {
                'seasonID': seasonId,
            },
            query: {
                'include': include,
                'filter[stage]': filterStage,
            },
        });
    }

}
