/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type type_quoteBlock = {
    text: string;
    source?: string;
    type: string;
    alignment: type_quoteBlock.alignment;
    behalf?: string;
};

export namespace type_quoteBlock {

    export enum alignment {
        REGULAR = 'regular',
        FULLWIDTH = 'fullwidth',
        LEFT = 'left',
        RIGHT = 'right',
    }


}

