import type { AxiosRequestConfig } from 'axios';

import { BaseHttpRequest, CancelablePromise } from '@common/clients/api';
import { ApiRequestOptions } from '@common/clients/api/core/ApiRequestOptions';
import { request as __request } from '@common/clients/request';
import { ContextData } from '@common/defaults/ContextData';

const defaultTimeout = Number(process.env.NEXT_PUBLIC_API_TIMEOUT) || 5000;

export class ApiBaseRequest extends BaseHttpRequest {
    axiosOptions: AxiosRequestConfig | undefined;
    constructor(props: ContextData, isClientSide: boolean, axiosOptions?: AxiosRequestConfig) {
        super({
            BASE:
                isClientSide || !props.config.api.internalHost
                    ? props.config.api.host
                    : props.config.api.internalHost,
            VERSION: '1.0.0',
            WITH_CREDENTIALS: true,
            CREDENTIALS: isClientSide ? 'same-origin' : 'include',
            HEADERS: !isClientSide
                ? {
                      Cookie: `userID=${props.userID}; userSessionID=${props.userSessionID}; publishing_session=${props.magnoliaSessionID};`,
                  }
                : {},
        });

        this.axiosOptions = axiosOptions;
    }

    public request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...this.config,
            TIMEOUT: defaultTimeout,
        };
        // @ts-ignore
        return __request<T>(extendedConfig, options, undefined, this.axiosOptions);
    }
}
