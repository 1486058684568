/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { KioskAccessV1Service } from './services/KioskAccessV1Service';
import { MobileappCommentsV1Service } from './services/MobileappCommentsV1Service';
import { MobileAppContentV5BettingService } from './services/MobileAppContentV5BettingService';
import { MobileAppContentV5BookmarksService } from './services/MobileAppContentV5BookmarksService';
import { MobileAppContentV5CompetitionsService } from './services/MobileAppContentV5CompetitionsService';
import { MobileAppContentV5MediaService } from './services/MobileAppContentV5MediaService';
import { MobileAppContentV5MenuService } from './services/MobileAppContentV5MenuService';
import { MobileAppContentV5NewsService } from './services/MobileAppContentV5NewsService';
import { MobileAppContentV5PersonalisationService } from './services/MobileAppContentV5PersonalisationService';
import { MobileAppContentV5ProService } from './services/MobileAppContentV5ProService';
import { MobileAppContentV6BettingService } from './services/MobileAppContentV6BettingService';
import { MobileAppContentV6BookmarksService } from './services/MobileAppContentV6BookmarksService';
import { MobileAppContentV6CompetitionsService } from './services/MobileAppContentV6CompetitionsService';
import { MobileAppContentV6MediaService } from './services/MobileAppContentV6MediaService';
import { MobileAppContentV6MenuService } from './services/MobileAppContentV6MenuService';
import { MobileAppContentV6NewsService } from './services/MobileAppContentV6NewsService';
import { MobileAppContentV6PaywallService } from './services/MobileAppContentV6PaywallService';
import { MobileAppContentV6PersonalisationService } from './services/MobileAppContentV6PersonalisationService';
import { MobileAppContentV6ProService } from './services/MobileAppContentV6ProService';
import { MobileAppContentV7BettingService } from './services/MobileAppContentV7BettingService';
import { MobileAppContentV7BookmarksService } from './services/MobileAppContentV7BookmarksService';
import { MobileAppContentV7CompetitionsService } from './services/MobileAppContentV7CompetitionsService';
import { MobileAppContentV7MediaService } from './services/MobileAppContentV7MediaService';
import { MobileAppContentV7MenuService } from './services/MobileAppContentV7MenuService';
import { MobileAppContentV7NewsService } from './services/MobileAppContentV7NewsService';
import { MobileAppContentV7PaywallService } from './services/MobileAppContentV7PaywallService';
import { MobileAppContentV7PollService } from './services/MobileAppContentV7PollService';
import { MobileAppContentV7ProService } from './services/MobileAppContentV7ProService';
import { MobileappV1BookmarksService } from './services/MobileappV1BookmarksService';
import { MobileappV1CompetitionsService } from './services/MobileappV1CompetitionsService';
import { MobileappV1MediaService } from './services/MobileappV1MediaService';
import { MobileappV1MenuService } from './services/MobileappV1MenuService';
import { MobileappV1NewsService } from './services/MobileappV1NewsService';
import { MobileappV1PersonalisationService } from './services/MobileappV1PersonalisationService';
import { MobileappV1ProService } from './services/MobileappV1ProService';
import { MobileappV2NewsService } from './services/MobileappV2NewsService';
import { MobileappV2ProService } from './services/MobileappV2ProService';
import { MobileappV3NewsService } from './services/MobileappV3NewsService';
import { MobileappV4NewsService } from './services/MobileappV4NewsService';
import { SportDataV1BettingService } from './services/SportDataV1BettingService';
import { SportDataV1MatchService } from './services/SportDataV1MatchService';
import { SportDataV1MatchesService } from './services/SportDataV1MatchesService';
import { SportDataV1PlayerService } from './services/SportDataV1PlayerService';
import { SportDataV1TeamService } from './services/SportDataV1TeamService';
import { SportDataV1TournamentService } from './services/SportDataV1TournamentService';
import { SportDataV1WebEndpointsService } from './services/SportDataV1WebEndpointsService';
import { SubscriptionsService } from './services/SubscriptionsService';
import { WebsiteAbtestService } from './services/WebsiteAbtestService';
import { WebsiteAuthorsService } from './services/WebsiteAuthorsService';
import { WebsiteBettingService } from './services/WebsiteBettingService';
import { WebsiteBookmarkService } from './services/WebsiteBookmarkService';
import { WebsiteCombinationService } from './services/WebsiteCombinationService';
import { WebsiteDialoguesService } from './services/WebsiteDialoguesService';
import { WebsiteMediaService } from './services/WebsiteMediaService';
import { WebsiteNewsService } from './services/WebsiteNewsService';
import { WebsiteOnboardingService } from './services/WebsiteOnboardingService';
import { WebsitePollsService } from './services/WebsitePollsService';
import { WebsiteProService } from './services/WebsiteProService';
import { WebsiteProNewsletterService } from './services/WebsiteProNewsletterService';
import { WebsiteSubscriptionsService } from './services/WebsiteSubscriptionsService';
import { WebsiteUserService } from './services/WebsiteUserService';
import { WebsiteVideoCarouselService } from './services/WebsiteVideoCarouselService';
import { WebsiteVoteService } from './services/WebsiteVoteService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ViApiClient {

    public readonly kioskAccessV1: KioskAccessV1Service;
    public readonly mobileappCommentsV1: MobileappCommentsV1Service;
    public readonly mobileAppContentV5Betting: MobileAppContentV5BettingService;
    public readonly mobileAppContentV5Bookmarks: MobileAppContentV5BookmarksService;
    public readonly mobileAppContentV5Competitions: MobileAppContentV5CompetitionsService;
    public readonly mobileAppContentV5Media: MobileAppContentV5MediaService;
    public readonly mobileAppContentV5Menu: MobileAppContentV5MenuService;
    public readonly mobileAppContentV5News: MobileAppContentV5NewsService;
    public readonly mobileAppContentV5Personalisation: MobileAppContentV5PersonalisationService;
    public readonly mobileAppContentV5Pro: MobileAppContentV5ProService;
    public readonly mobileAppContentV6Betting: MobileAppContentV6BettingService;
    public readonly mobileAppContentV6Bookmarks: MobileAppContentV6BookmarksService;
    public readonly mobileAppContentV6Competitions: MobileAppContentV6CompetitionsService;
    public readonly mobileAppContentV6Media: MobileAppContentV6MediaService;
    public readonly mobileAppContentV6Menu: MobileAppContentV6MenuService;
    public readonly mobileAppContentV6News: MobileAppContentV6NewsService;
    public readonly mobileAppContentV6Paywall: MobileAppContentV6PaywallService;
    public readonly mobileAppContentV6Personalisation: MobileAppContentV6PersonalisationService;
    public readonly mobileAppContentV6Pro: MobileAppContentV6ProService;
    public readonly mobileAppContentV7Betting: MobileAppContentV7BettingService;
    public readonly mobileAppContentV7Bookmarks: MobileAppContentV7BookmarksService;
    public readonly mobileAppContentV7Competitions: MobileAppContentV7CompetitionsService;
    public readonly mobileAppContentV7Media: MobileAppContentV7MediaService;
    public readonly mobileAppContentV7Menu: MobileAppContentV7MenuService;
    public readonly mobileAppContentV7News: MobileAppContentV7NewsService;
    public readonly mobileAppContentV7Paywall: MobileAppContentV7PaywallService;
    public readonly mobileAppContentV7Poll: MobileAppContentV7PollService;
    public readonly mobileAppContentV7Pro: MobileAppContentV7ProService;
    public readonly mobileappV1Bookmarks: MobileappV1BookmarksService;
    public readonly mobileappV1Competitions: MobileappV1CompetitionsService;
    public readonly mobileappV1Media: MobileappV1MediaService;
    public readonly mobileappV1Menu: MobileappV1MenuService;
    public readonly mobileappV1News: MobileappV1NewsService;
    public readonly mobileappV1Personalisation: MobileappV1PersonalisationService;
    public readonly mobileappV1Pro: MobileappV1ProService;
    public readonly mobileappV2News: MobileappV2NewsService;
    public readonly mobileappV2Pro: MobileappV2ProService;
    public readonly mobileappV3News: MobileappV3NewsService;
    public readonly mobileappV4News: MobileappV4NewsService;
    public readonly sportDataV1Betting: SportDataV1BettingService;
    public readonly sportDataV1Match: SportDataV1MatchService;
    public readonly sportDataV1Matches: SportDataV1MatchesService;
    public readonly sportDataV1Player: SportDataV1PlayerService;
    public readonly sportDataV1Team: SportDataV1TeamService;
    public readonly sportDataV1Tournament: SportDataV1TournamentService;
    public readonly sportDataV1WebEndpoints: SportDataV1WebEndpointsService;
    public readonly subscriptions: SubscriptionsService;
    public readonly websiteAbtest: WebsiteAbtestService;
    public readonly websiteAuthors: WebsiteAuthorsService;
    public readonly websiteBetting: WebsiteBettingService;
    public readonly websiteBookmark: WebsiteBookmarkService;
    public readonly websiteCombination: WebsiteCombinationService;
    public readonly websiteDialogues: WebsiteDialoguesService;
    public readonly websiteMedia: WebsiteMediaService;
    public readonly websiteNews: WebsiteNewsService;
    public readonly websiteOnboarding: WebsiteOnboardingService;
    public readonly websitePolls: WebsitePollsService;
    public readonly websitePro: WebsiteProService;
    public readonly websiteProNewsletter: WebsiteProNewsletterService;
    public readonly websiteSubscriptions: WebsiteSubscriptionsService;
    public readonly websiteUser: WebsiteUserService;
    public readonly websiteVideoCarousel: WebsiteVideoCarouselService;
    public readonly websiteVote: WebsiteVoteService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.kioskAccessV1 = new KioskAccessV1Service(this.request);
        this.mobileappCommentsV1 = new MobileappCommentsV1Service(this.request);
        this.mobileAppContentV5Betting = new MobileAppContentV5BettingService(this.request);
        this.mobileAppContentV5Bookmarks = new MobileAppContentV5BookmarksService(this.request);
        this.mobileAppContentV5Competitions = new MobileAppContentV5CompetitionsService(this.request);
        this.mobileAppContentV5Media = new MobileAppContentV5MediaService(this.request);
        this.mobileAppContentV5Menu = new MobileAppContentV5MenuService(this.request);
        this.mobileAppContentV5News = new MobileAppContentV5NewsService(this.request);
        this.mobileAppContentV5Personalisation = new MobileAppContentV5PersonalisationService(this.request);
        this.mobileAppContentV5Pro = new MobileAppContentV5ProService(this.request);
        this.mobileAppContentV6Betting = new MobileAppContentV6BettingService(this.request);
        this.mobileAppContentV6Bookmarks = new MobileAppContentV6BookmarksService(this.request);
        this.mobileAppContentV6Competitions = new MobileAppContentV6CompetitionsService(this.request);
        this.mobileAppContentV6Media = new MobileAppContentV6MediaService(this.request);
        this.mobileAppContentV6Menu = new MobileAppContentV6MenuService(this.request);
        this.mobileAppContentV6News = new MobileAppContentV6NewsService(this.request);
        this.mobileAppContentV6Paywall = new MobileAppContentV6PaywallService(this.request);
        this.mobileAppContentV6Personalisation = new MobileAppContentV6PersonalisationService(this.request);
        this.mobileAppContentV6Pro = new MobileAppContentV6ProService(this.request);
        this.mobileAppContentV7Betting = new MobileAppContentV7BettingService(this.request);
        this.mobileAppContentV7Bookmarks = new MobileAppContentV7BookmarksService(this.request);
        this.mobileAppContentV7Competitions = new MobileAppContentV7CompetitionsService(this.request);
        this.mobileAppContentV7Media = new MobileAppContentV7MediaService(this.request);
        this.mobileAppContentV7Menu = new MobileAppContentV7MenuService(this.request);
        this.mobileAppContentV7News = new MobileAppContentV7NewsService(this.request);
        this.mobileAppContentV7Paywall = new MobileAppContentV7PaywallService(this.request);
        this.mobileAppContentV7Poll = new MobileAppContentV7PollService(this.request);
        this.mobileAppContentV7Pro = new MobileAppContentV7ProService(this.request);
        this.mobileappV1Bookmarks = new MobileappV1BookmarksService(this.request);
        this.mobileappV1Competitions = new MobileappV1CompetitionsService(this.request);
        this.mobileappV1Media = new MobileappV1MediaService(this.request);
        this.mobileappV1Menu = new MobileappV1MenuService(this.request);
        this.mobileappV1News = new MobileappV1NewsService(this.request);
        this.mobileappV1Personalisation = new MobileappV1PersonalisationService(this.request);
        this.mobileappV1Pro = new MobileappV1ProService(this.request);
        this.mobileappV2News = new MobileappV2NewsService(this.request);
        this.mobileappV2Pro = new MobileappV2ProService(this.request);
        this.mobileappV3News = new MobileappV3NewsService(this.request);
        this.mobileappV4News = new MobileappV4NewsService(this.request);
        this.sportDataV1Betting = new SportDataV1BettingService(this.request);
        this.sportDataV1Match = new SportDataV1MatchService(this.request);
        this.sportDataV1Matches = new SportDataV1MatchesService(this.request);
        this.sportDataV1Player = new SportDataV1PlayerService(this.request);
        this.sportDataV1Team = new SportDataV1TeamService(this.request);
        this.sportDataV1Tournament = new SportDataV1TournamentService(this.request);
        this.sportDataV1WebEndpoints = new SportDataV1WebEndpointsService(this.request);
        this.subscriptions = new SubscriptionsService(this.request);
        this.websiteAbtest = new WebsiteAbtestService(this.request);
        this.websiteAuthors = new WebsiteAuthorsService(this.request);
        this.websiteBetting = new WebsiteBettingService(this.request);
        this.websiteBookmark = new WebsiteBookmarkService(this.request);
        this.websiteCombination = new WebsiteCombinationService(this.request);
        this.websiteDialogues = new WebsiteDialoguesService(this.request);
        this.websiteMedia = new WebsiteMediaService(this.request);
        this.websiteNews = new WebsiteNewsService(this.request);
        this.websiteOnboarding = new WebsiteOnboardingService(this.request);
        this.websitePolls = new WebsitePollsService(this.request);
        this.websitePro = new WebsiteProService(this.request);
        this.websiteProNewsletter = new WebsiteProNewsletterService(this.request);
        this.websiteSubscriptions = new WebsiteSubscriptionsService(this.request);
        this.websiteUser = new WebsiteUserService(this.request);
        this.websiteVideoCarousel = new WebsiteVideoCarouselService(this.request);
        this.websiteVote = new WebsiteVoteService(this.request);
    }
}

