/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ArticleVoteResponse = {
    message?: string;
    vote?: ArticleVoteResponse.vote;
};

export namespace ArticleVoteResponse {

    export enum vote {
        UP = 'up',
        DOWN = 'down',
    }


}

