/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DeepLink } from './DeepLink';

export type RelatedNewsItem = {
    deepLink: DeepLink;
    imageUrl?: string;
    contentBlocks?: Array<string>;
    label?: string;
    title: string;
    type: RelatedNewsItem.type;
    url: string;
};

export namespace RelatedNewsItem {

    export enum type {
        ARTICLE = 'ARTICLE',
        PRO = 'PRO',
        ADVERTORIAL = 'ADVERTORIAL',
        AUDIO = 'AUDIO',
        VIDEO = 'VIDEO',
        MARKETING = 'MARKETING',
    }


}

