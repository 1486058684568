/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DriverResponse } from '../models/DriverResponse';
import type { DriversBySeasonResponse } from '../models/DriversBySeasonResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DriversService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Driver Race Result By Season Id
     * <h2 id="introduction"><strong>Introduction</strong></h2>
     * <p>This endpoint also represents the points table for the driver championship.</p>
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>results.track</code></p>
     *
     * @returns DriverResponse Successful response
     * @throws ApiError
     */
    public getDriverRaceResultsBySeasonId({
        driverId,
        seasonId,
        include,
    }: {
        driverId: number,
        seasonId: number,
        include?: Array<'results.track'>,
    }): CancelablePromise<DriverResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/drivers/{driverID}/seasons/{seasonID}/results',
            path: {
                'driverID': driverId,
                'seasonID': seasonId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Drivers by Season
     * <h2 id="introduction"><strong>Introduction</strong></h2>
     * <p>This endpoint also represents the points table for the driver championship.</p>
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>driver</code> <code>team</code></p>
     *
     * @returns DriversBySeasonResponse Successful response
     * @throws ApiError
     */
    public getDriversBySeason({
        seasonId,
        include,
    }: {
        seasonId: number,
        include?: Array<'driver' | 'team'>,
    }): CancelablePromise<DriversBySeasonResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/drivers/season/{seasonID}',
            path: {
                'seasonID': seasonId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Driver By ID
     * <h2 id="introduction"><strong>Introduction</strong></h2>
     * <p>This endpoint also represents the points table for the driver championship.</p>
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>driver</code></p>
     *
     * @returns DriverResponse Successful response
     * @throws ApiError
     */
    public getDriverById({
        driverId,
        include,
    }: {
        driverId: number,
        include?: Array<'team'>,
    }): CancelablePromise<DriverResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/drivers/{driverID}',
            path: {
                'driverID': driverId,
            },
            query: {
                'include': include,
            },
        });
    }

}
