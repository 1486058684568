/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type type_streamerBlock = {
    text: string;
    type: string;
    alignment: type_streamerBlock.alignment;
};

export namespace type_streamerBlock {

    export enum alignment {
        REGULAR = 'regular',
        FULLWIDTH = 'fullwidth',
        LEFT = 'left',
        RIGHT = 'right',
    }


}

