import type { AxiosRequestConfig } from 'axios';

import { request as __request } from '@common/clients/request';
import { BaseHttpRequest, CancelablePromise } from '@common/clients/vi';
import { ApiRequestOptions } from '@common/clients/vi/core/ApiRequestOptions';
import { ContextData } from '@common/defaults/ContextData';

const timeout = Number(process.env.NEXT_PUBLIC_VI_API_TIMEOUT) || 5000;

export class ViApiBaseRequest extends BaseHttpRequest {
    axiosOptions: AxiosRequestConfig | undefined;

    constructor(props: ContextData, isClientSide: boolean, axiosOptions?: AxiosRequestConfig) {
        super({
            BASE:
                isClientSide || !props.config.viapi.internalHost
                    ? props.config.viapi.host
                    : props.config.viapi.internalHost,
            VERSION: '1.0.0',
            WITH_CREDENTIALS: false,
            CREDENTIALS: 'omit',
        });

        this.axiosOptions = {
            ...axiosOptions,
            headers: {
                ...axiosOptions?.headers,
                contentType: axiosOptions?.headers?.contentType || 'undefined',
            },
        };
    }

    public request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...this.config,
            TIMEOUT: timeout,
        };
        // @ts-ignore
        return __request(extendedConfig, options, undefined, this.axiosOptions);
    }
}
