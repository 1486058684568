/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SportDataTournamentRanking } from '../models/SportDataTournamentRanking';
import type { SportDataTournamentStandings } from '../models/SportDataTournamentStandings';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SportDataV1TournamentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get tournament standings
     * Get tournament standings
     * @returns SportDataTournamentStandings OK
     * @throws ApiError
     */
    public getTournamentStanding({
        tournamentId = 'bp1sjjiswd4t3nw86vf6yq7hm',
    }: {
        /**
         * OPTA ID of the requested tournament
         */
        tournamentId?: string,
    }): CancelablePromise<SportDataTournamentStandings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/tournament/{tournamentId}/standings',
            path: {
                'tournamentId': tournamentId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get tournament rankings
     * Get tournament rankings
     * @returns SportDataTournamentRanking OK
     * @throws ApiError
     */
    public getTournamentRankings({
        tournamentId = 'bp1sjjiswd4t3nw86vf6yq7hm',
    }: {
        /**
         * OPTA ID of the requested tournament
         */
        tournamentId?: string,
    }): CancelablePromise<SportDataTournamentRanking> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sportdata/v1/tournament/{tournamentId}/rankings',
            path: {
                'tournamentId': tournamentId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized access`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
    }

}
