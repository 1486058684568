/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PaywallContent } from './PaywallContent';

export type type_paywallBlock = {
    paywallType: type_paywallBlock.paywallType;
    type: string;
    fallback?: PaywallContent;
};

export namespace type_paywallBlock {

    export enum paywallType {
        NON_PRO = 'NON_PRO',
        CLUB = 'CLUB',
        LIMIT_REACHED = 'LIMIT_REACHED',
        SUSPENDED = 'SUSPENDED',
        IOS = 'IOS',
    }


}

