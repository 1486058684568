/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PairBettingProviderLinkTypeEnumString = {
    left?: PairBettingProviderLinkTypeEnumString.left;
    right?: string;
    value?: string;
    key?: PairBettingProviderLinkTypeEnumString.key;
};

export namespace PairBettingProviderLinkTypeEnumString {

    export enum left {
        MATCH_OVERVIEW = 'MATCH_OVERVIEW',
        HOMEPAGE_OVERVIEW = 'HOMEPAGE_OVERVIEW',
        MATCH_DETAIL = 'MATCH_DETAIL',
        MATCH_OVERVIEW_TOPIC = 'MATCH_OVERVIEW_TOPIC',
        PROMOTIONAL_BAR = 'PROMOTIONAL_BAR',
    }

    export enum key {
        MATCH_OVERVIEW = 'MATCH_OVERVIEW',
        HOMEPAGE_OVERVIEW = 'HOMEPAGE_OVERVIEW',
        MATCH_DETAIL = 'MATCH_DETAIL',
        MATCH_OVERVIEW_TOPIC = 'MATCH_OVERVIEW_TOPIC',
        PROMOTIONAL_BAR = 'PROMOTIONAL_BAR',
    }


}

