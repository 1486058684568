import type { AxiosRequestConfig } from 'axios';

import { request as __request } from '@common/clients/request/request';
import { ApiRequestOptions } from '@common/clients/vi/core/ApiRequestOptions';
import { BaseHttpRequest } from '@common/clients/vi/core/BaseHttpRequest';
import { CancelablePromise } from '@common/clients/vi/core/CancelablePromise';

const timeout = Number(process.env.VI_API_TIMEOUT) || 5000;

export class ViApiFromApiBaseRequest extends BaseHttpRequest {
    axiosOptions: AxiosRequestConfig | undefined;

    constructor(axiosOptions?: AxiosRequestConfig) {
        super({
            BASE: process.env.VI_API_HOST || '',
            VERSION: '1.0.0',
            WITH_CREDENTIALS: false,
            CREDENTIALS: 'omit',
        });

        this.axiosOptions = {
            ...axiosOptions,
            headers: {
                ...axiosOptions?.headers,
                contentType: axiosOptions?.headers?.contentType || 'undefined',
            },
        };
    }

    public request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...this.config,
            TIMEOUT: timeout,
        };
        // @ts-ignore
        return __request(extendedConfig, options, undefined, this.axiosOptions);
    }
}
