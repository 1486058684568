/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ViApiClient } from './ViApiClient';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { AllPromotionBars } from './models/AllPromotionBars';
export type { ArchiveItem } from './models/ArchiveItem';
export { Article } from './models/Article';
export { ArticleVoteResponse } from './models/ArticleVoteResponse';
export type { Author } from './models/Author';
export type { BettingEnableEndpointResponse } from './models/BettingEnableEndpointResponse';
export type { BettingProvider } from './models/BettingProvider';
export type { Block } from './models/Block';
export type { CategoryItem } from './models/CategoryItem';
export type { CompetitionGroupResponse } from './models/CompetitionGroupResponse';
export { DeepLink } from './models/DeepLink';
export type { Dialogue } from './models/Dialogue';
export type { EntitlementsModel } from './models/EntitlementsModel';
export type { ExitArticle } from './models/ExitArticle';
export type { GeneralPromotionBar } from './models/GeneralPromotionBar';
export type { GeneralPromotionBarItem } from './models/GeneralPromotionBarItem';
export type { HighlightsResponse } from './models/HighlightsResponse';
export type { Hypermedia } from './models/Hypermedia';
export type { InputPart } from './models/InputPart';
export type { InvalidPrenlyResponse } from './models/InvalidPrenlyResponse';
export type { LinkWrapper } from './models/LinkWrapper';
export type { MatchCenterBettingPromotionBlock } from './models/MatchCenterBettingPromotionBlock';
export type { MatchEventsService } from './models/MatchEventsService';
export type { MediaMetadata } from './models/MediaMetadata';
export type { MenuGroup } from './models/MenuGroup';
export type { MenuItem } from './models/MenuItem';
export type { MenuResponse } from './models/MenuResponse';
export { MobileArticleVote } from './models/MobileArticleVote';
export type { MobileGridBlock } from './models/MobileGridBlock';
export type { MobileGridBlockItem } from './models/MobileGridBlockItem';
export type { MobileGridsSelectionResponse } from './models/MobileGridsSelectionResponse';
export type { MobileNewsItem } from './models/MobileNewsItem';
export type { MultipartFormDataInput } from './models/MultipartFormDataInput';
export type { NewsPromotionBar } from './models/NewsPromotionBar';
export type { OptaMatchItem } from './models/OptaMatchItem';
export type { OptaPromotionBar } from './models/OptaPromotionBar';
export { PairBettingProviderLinkTypeEnumString } from './models/PairBettingProviderLinkTypeEnumString';
export type { PaywallContent } from './models/PaywallContent';
export type { PersonalisationEndpointResponse } from './models/PersonalisationEndpointResponse';
export type { PersonalisationOption } from './models/PersonalisationOption';
export type { PollVoteRequest } from './models/PollVoteRequest';
export type { ProMetadata } from './models/ProMetadata';
export type { PromotionBar } from './models/PromotionBar';
export type { PromotionItem } from './models/PromotionItem';
export type { PushData } from './models/PushData';
export type { PushRequest } from './models/PushRequest';
export type { RedirectsRequest } from './models/RedirectsRequest';
export type { RedirectsResponse } from './models/RedirectsResponse';
export { RelatedNewsItem } from './models/RelatedNewsItem';
export type { RelatedNewsItemGroup } from './models/RelatedNewsItemGroup';
export type { RelatedTag } from './models/RelatedTag';
export type { SigninModel } from './models/SigninModel';
export type { SportDataBookmaker } from './models/SportDataBookmaker';
export type { SportDataCompetition } from './models/SportDataCompetition';
export type { SportDataMatch } from './models/SportDataMatch';
export type { SportDataMatchDetails } from './models/SportDataMatchDetails';
export type { SportDataMatchEvent } from './models/SportDataMatchEvent';
export type { SportDataMatchStatistic } from './models/SportDataMatchStatistic';
export type { SportDataPlayer } from './models/SportDataPlayer';
export type { SportDataPlayerClubTeam } from './models/SportDataPlayerClubTeam';
export type { SportDataPlayerMembership } from './models/SportDataPlayerMembership';
export type { SportDataPlayerPassport } from './models/SportDataPlayerPassport';
export type { SportDataPlayerStats } from './models/SportDataPlayerStats';
export type { SportDataTeam } from './models/SportDataTeam';
export type { SportDataTeamRanking } from './models/SportDataTeamRanking';
export type { SportDataTeamRankings } from './models/SportDataTeamRankings';
export type { SportDataTeamSelection } from './models/SportDataTeamSelection';
export type { SportDataTeamStanding } from './models/SportDataTeamStanding';
export type { SportDataTeamStatistics } from './models/SportDataTeamStatistics';
export type { SportDataTournament } from './models/SportDataTournament';
export type { SportDataTournamentRanking } from './models/SportDataTournamentRanking';
export type { SportDataTournamentRankingPosition } from './models/SportDataTournamentRankingPosition';
export type { SportDataTournamentStage } from './models/SportDataTournamentStage';
export type { SportDataTournamentStandings } from './models/SportDataTournamentStandings';
export type { SportMonksBookmaker } from './models/SportMonksBookmaker';
export type { type_embedBlock } from './models/type_embedBlock';
export type { type_frameBlock } from './models/type_frameBlock';
export type { type_htmlBlock } from './models/type_htmlBlock';
export type { type_imageBlock } from './models/type_imageBlock';
export type { type_mediaBlock } from './models/type_mediaBlock';
export { type_paywallBlock } from './models/type_paywallBlock';
export { type_quoteBlock } from './models/type_quoteBlock';
export type { type_richTextBlock } from './models/type_richTextBlock';
export { type_streamerBlock } from './models/type_streamerBlock';
export type { UserInfoError } from './models/UserInfoError';
export type { UserInfoRequest } from './models/UserInfoRequest';
export type { UserInfoResponse } from './models/UserInfoResponse';
export type { ValidPrenlyResponse } from './models/ValidPrenlyResponse';
export type { VIOdd } from './models/VIOdd';
export type { VIThreeWayOdd } from './models/VIThreeWayOdd';
export type { WebsiteAddressCheckRequest } from './models/WebsiteAddressCheckRequest';
export type { WebsiteAddressCheckResponse } from './models/WebsiteAddressCheckResponse';
export { WebsiteRestNewsItem } from './models/WebsiteRestNewsItem';
export type { WebsiteVoucherCheckRequest } from './models/WebsiteVoucherCheckRequest';
export type { WebsiteVoucherCheckResponse } from './models/WebsiteVoucherCheckResponse';
export type { WebsiteVoucherPriceResponse } from './models/WebsiteVoucherPriceResponse';

export { KioskAccessV1Service } from './services/KioskAccessV1Service';
export { MobileappCommentsV1Service } from './services/MobileappCommentsV1Service';
export { MobileAppContentV5BettingService } from './services/MobileAppContentV5BettingService';
export { MobileAppContentV5BookmarksService } from './services/MobileAppContentV5BookmarksService';
export { MobileAppContentV5CompetitionsService } from './services/MobileAppContentV5CompetitionsService';
export { MobileAppContentV5MediaService } from './services/MobileAppContentV5MediaService';
export { MobileAppContentV5MenuService } from './services/MobileAppContentV5MenuService';
export { MobileAppContentV5NewsService } from './services/MobileAppContentV5NewsService';
export { MobileAppContentV5PersonalisationService } from './services/MobileAppContentV5PersonalisationService';
export { MobileAppContentV5ProService } from './services/MobileAppContentV5ProService';
export { MobileAppContentV6BettingService } from './services/MobileAppContentV6BettingService';
export { MobileAppContentV6BookmarksService } from './services/MobileAppContentV6BookmarksService';
export { MobileAppContentV6CompetitionsService } from './services/MobileAppContentV6CompetitionsService';
export { MobileAppContentV6MediaService } from './services/MobileAppContentV6MediaService';
export { MobileAppContentV6MenuService } from './services/MobileAppContentV6MenuService';
export { MobileAppContentV6NewsService } from './services/MobileAppContentV6NewsService';
export { MobileAppContentV6PaywallService } from './services/MobileAppContentV6PaywallService';
export { MobileAppContentV6PersonalisationService } from './services/MobileAppContentV6PersonalisationService';
export { MobileAppContentV6ProService } from './services/MobileAppContentV6ProService';
export { MobileAppContentV7BettingService } from './services/MobileAppContentV7BettingService';
export { MobileAppContentV7BookmarksService } from './services/MobileAppContentV7BookmarksService';
export { MobileAppContentV7CompetitionsService } from './services/MobileAppContentV7CompetitionsService';
export { MobileAppContentV7MediaService } from './services/MobileAppContentV7MediaService';
export { MobileAppContentV7MenuService } from './services/MobileAppContentV7MenuService';
export { MobileAppContentV7NewsService } from './services/MobileAppContentV7NewsService';
export { MobileAppContentV7PaywallService } from './services/MobileAppContentV7PaywallService';
export { MobileAppContentV7PollService } from './services/MobileAppContentV7PollService';
export { MobileAppContentV7ProService } from './services/MobileAppContentV7ProService';
export { MobileappV1BookmarksService } from './services/MobileappV1BookmarksService';
export { MobileappV1CompetitionsService } from './services/MobileappV1CompetitionsService';
export { MobileappV1MediaService } from './services/MobileappV1MediaService';
export { MobileappV1MenuService } from './services/MobileappV1MenuService';
export { MobileappV1NewsService } from './services/MobileappV1NewsService';
export { MobileappV1PersonalisationService } from './services/MobileappV1PersonalisationService';
export { MobileappV1ProService } from './services/MobileappV1ProService';
export { MobileappV2NewsService } from './services/MobileappV2NewsService';
export { MobileappV2ProService } from './services/MobileappV2ProService';
export { MobileappV3NewsService } from './services/MobileappV3NewsService';
export { MobileappV4NewsService } from './services/MobileappV4NewsService';
export { SportDataV1BettingService } from './services/SportDataV1BettingService';
export { SportDataV1MatchService } from './services/SportDataV1MatchService';
export { SportDataV1MatchesService } from './services/SportDataV1MatchesService';
export { SportDataV1PlayerService } from './services/SportDataV1PlayerService';
export { SportDataV1TeamService } from './services/SportDataV1TeamService';
export { SportDataV1TournamentService } from './services/SportDataV1TournamentService';
export { SportDataV1WebEndpointsService } from './services/SportDataV1WebEndpointsService';
export { SubscriptionsService } from './services/SubscriptionsService';
export { WebsiteAbtestService } from './services/WebsiteAbtestService';
export { WebsiteAuthorsService } from './services/WebsiteAuthorsService';
export { WebsiteBettingService } from './services/WebsiteBettingService';
export { WebsiteBookmarkService } from './services/WebsiteBookmarkService';
export { WebsiteCombinationService } from './services/WebsiteCombinationService';
export { WebsiteDialoguesService } from './services/WebsiteDialoguesService';
export { WebsiteMediaService } from './services/WebsiteMediaService';
export { WebsiteNewsService } from './services/WebsiteNewsService';
export { WebsiteOnboardingService } from './services/WebsiteOnboardingService';
export { WebsitePollsService } from './services/WebsitePollsService';
export { WebsiteProService } from './services/WebsiteProService';
export { WebsiteProNewsletterService } from './services/WebsiteProNewsletterService';
export { WebsiteSubscriptionsService } from './services/WebsiteSubscriptionsService';
export { WebsiteUserService } from './services/WebsiteUserService';
export { WebsiteVideoCarouselService } from './services/WebsiteVideoCarouselService';
export { WebsiteVoteService } from './services/WebsiteVoteService';
