/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DeepLink = {
    type?: DeepLink.type;
    value?: string;
};

export namespace DeepLink {

    export enum type {
        ARTICLE = 'article',
        COMPETITION = 'competition',
        EXTERNAL = 'external',
        MATCH = 'match',
        MEDIA = 'media',
        PAGE = 'page',
        PLAYER = 'player',
        PRO_ARTICLE = 'proArticle',
        TEAM = 'team',
        VIDEO = 'video',
    }


}

