/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MobileArticleVote = {
    articleId: string;
    vote: MobileArticleVote.vote;
};

export namespace MobileArticleVote {

    export enum vote {
        UP = 'up',
        DOWN = 'down',
    }


}

