/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamResponse } from '../models/TeamResponse';
import type { TeamsBySeasonResponse } from '../models/TeamsBySeasonResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TeamsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Team by Season ID
     * <h2 id="introduction"><strong>Introduction</strong></h2>
     * <p>This endpoint also represents the points table for the team championship.</p>
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>team</code></p>
     *
     * @returns TeamsBySeasonResponse Successful response
     * @throws ApiError
     */
    public getTeamsBySeason({
        seasonId,
        include,
    }: {
        seasonId: number,
        include?: Array<'team'>,
    }): CancelablePromise<TeamsBySeasonResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/teams/season/{seasonID}',
            path: {
                'seasonID': seasonId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Team by ID
     * <h2 id="introduction"><strong>Introduction</strong></h2>
     * <p>This endpoint also represents the points table for the team championship.</p>
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>team</code></p>
     *
     * @returns TeamResponse Successful response
     * @throws ApiError
     */
    public getTeamById({
        teamId,
        include,
    }: {
        teamId: number,
        include?: Array<'drivers'>,
    }): CancelablePromise<TeamResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/teams/{teamID}',
            path: {
                'teamID': teamId,
            },
            query: {
                'include': include,
            },
        });
    }

    /**
     * Get Team Race Result By Season Id
     * <h2 id="introduction"><strong>Introduction</strong></h2>
     * <p>This endpoint also represents the points table for the driver championship.</p>
     * <h2 id="pagination"><strong>Pagination</strong></h2>
     * <p><strong>NO</strong> - Indicates if this endpoint contains pagination.</p>
     * <h2 id="nested-includes"><strong>Nested Includes</strong></h2>
     * <p><strong>10</strong> - You can add a max of <strong>10</strong> levels of nested includes.</p>
     * <h2 id="includes-options"><strong>Includes Options</strong></h2>
     * <p><code>results.track</code></p>
     *
     * @returns TeamResponse Successful response
     * @throws ApiError
     */
    public getTeamRaceResultsBySeasonId({
        teamId,
        seasonId,
        include,
    }: {
        teamId: number,
        seasonId: number,
        include?: Array<'results.track'>,
    }): CancelablePromise<TeamResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1.0/teams/{teamID}/seasons/{seasonID}',
            path: {
                'teamID': teamId,
                'seasonID': seasonId,
            },
            query: {
                'include': include,
            },
        });
    }

}
