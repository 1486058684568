import type { AxiosRequestConfig } from 'axios';

import { ArrayQueryType, request as __request } from '@common/clients/request';
import { BaseHttpRequest, CancelablePromise } from '@common/clients/sportmonks-f1';
import { ApiRequestOptions } from '@common/clients/sportmonks-f1/core/ApiRequestOptions';
import { ContextData } from '@common/defaults/ContextData';

const timeout = Number(process.env.NEXT_PUBLIC_SPORTMONKS_F1_TIMEOUT) || 5000;

export class SportmonksF1BaseRequest extends BaseHttpRequest {
    axiosOptions: AxiosRequestConfig | undefined;

    constructor(props: ContextData, isClientSide: boolean, axiosOptions?: AxiosRequestConfig) {
        super({
            BASE:
                isClientSide || !props.config.sportmonksF1.internalHost
                    ? props.config.sportmonksF1.host
                    : props.config.sportmonksF1.internalHost,
            VERSION: '1.0.0',
            WITH_CREDENTIALS: false,
            CREDENTIALS: 'omit',
        });
        this.axiosOptions = axiosOptions;
    }

    public request<T>(options: ApiRequestOptions): CancelablePromise<T> {
        const extendedConfig = {
            ...this.config,
            TIMEOUT: timeout,
            ARRAY_QUERY_TYPE: ArrayQueryType.COMMA_SEPARATED,
        };
        // @ts-ignore
        return __request(extendedConfig, options, undefined, this.axiosOptions);
    }
}
