/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Author } from './Author';
import type { Block } from './Block';
import type { RelatedNewsItem } from './RelatedNewsItem';
import type { RelatedNewsItemGroup } from './RelatedNewsItemGroup';
import type { RelatedTag } from './RelatedTag';

export type Article = {
    date: number;
    image?: string;
    relatedArticles?: Array<RelatedNewsItem>;
    commentingEnabled?: boolean;
    relatedItems?: Array<RelatedNewsItemGroup>;
    id: string;
    title: string;
    pro?: boolean;
    type?: Article.type;
    content?: Array<Block>;
    tags?: Array<RelatedTag>;
    authors?: Array<Author>;
};

export namespace Article {

    export enum type {
        ARTICLE = 'ARTICLE',
        PRO = 'PRO',
        ADVERTORIAL = 'ADVERTORIAL',
        AUDIO = 'AUDIO',
        VIDEO = 'VIDEO',
        MARKETING = 'MARKETING',
    }


}

